.container {
    /* border: 1px solid red; */
    width: 100%;
    max-width: 1400px;
    flex-grow: 1;
    align-self: center;
    /* background: white; */
    background: #F7F7F7;
}
.container .content {
    padding: 10px 20px;
    margin-top: 100px;
}