.row {
    /* border: 1px solid red; */
}

.painItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 15px 20px 25px 20px; */
    padding: 40px 30px;
    margin-bottom: 15px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 86px 34px rgba(0, 0, 0, 0.01), 0px 48px 29px rgba(0, 0, 0, 0.05), 0px 22px 22px rgba(0, 0, 0, 0.09), 0px 5px 12px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
}

.painItem:hover {
    background: #F0F0F1;
    box-shadow: 0px 86px 34px rgba(0, 0, 0, 0.01), 0px 48px 29px rgba(0, 0, 0, 0.05), 0px 22px 22px rgba(0, 0, 0, 0.09), 0px 5px 12px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
}
.painItem:focus {
    background: #F0F0F1;
    border: 2px dashed #0E2C80;
    box-shadow: 0px 86px 34px rgba(0, 0, 0, 0.01), 0px 48px 29px rgba(0, 0, 0, 0.05), 0px 22px 22px rgba(0, 0, 0, 0.09), 0px 5px 12px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
}

.painItem .painType {
    font-weight: 700;
    font-size: 28px;
    line-height: 33px;
    margin-top:10px;
}
.painItem .painTypeEN {
    margin-top:10px;
    font-weight: 700;
    font-size: 28px;
    line-height: 33px;
    color: rgba(74, 74, 74, 0.6);
}

.painItem .lottieAnimation {
    margin-bottom: 15px;
}

h1 {
    font-weight:400;
    text-align: center;
    margin-bottom: 40px;
    font-size: 32px;
    line-height: 38px;
}



@media screen and (max-width: 767px) {
    .painItem .painType {
        font-weight: 700;
        font-size: 15px;
        line-height: 15px;
    }
    .painItem .painTypeEN {
        font-weight: 700;
        font-size: 15px;
        line-height: 15px;
    }
}
@media screen and (max-width: 440px) {
    .painItem .painType {
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
    }
    .painItem .painTypeEN {
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
    }
}
@media screen and (max-width: 375px) {
    .painItem .painType {
        font-weight: 700;
        font-size: 12px;
        line-height: 12px;
    }
    .painItem .painTypeEN {
        font-weight: 700;
        font-size: 12px;
        line-height: 12px;
    }
}
@media screen and (min-width: 1024px) {
    .painItem .painType {
        font-weight: 700;
        font-size: 28px;
        line-height: 33px;
    }
    .painItem .painTypeEN {
        font-weight: 700;
        font-size: 28px;
        line-height: 33px;
    }
}
@media screen and (min-width: 768px) {
    .painItem .painType {
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
    }
    .painItem .painTypeEN {
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
    }
}
@media screen and (min-width: 1200px) {
}