footer {
    /* background: #0D4F8B;
    color: white; */
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    
    background: white;
    color: black;
    box-shadow: 81px 155px 70px rgba(0, 0, 0, 0.01), 45px 87px 59px rgba(0, 0, 0, 0.04), 20px 39px 44px rgba(0, 0, 0, 0.07), 5px 10px 24px rgba(0, 0, 0, 0.08), 0px 0px 0px rgba(0, 0, 0, 0.08);

}

footer .footerContainer {
    width: 100%;
    /* max-width: 1400px; */
    flex-grow: 1;
    align-self: center;
}

footer .footerContent {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}